body {
  background: #fff5f2;
}
.Logo {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 27px;
  margin-bottom: -65px;
  border-radis: 100%;
  padding: 5px;
}
.form {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: auto;
  margin-top: 0;
  /* margin-bottom: 40%; */
  height: auto;
  padding: 2em 1em 1em 1em;
  background: white;
  border: solid 1px rgb(234, 234, 234);
  border-radius: 10px;
  font-family:"Comfortaa"
}
.form h2 {
  text-align: center;
  margin-bottom: 55px;
  font-weight: 400;
  margin-top: -10px;
}
.form p {
  text-align: center;
}
.form input {
  padding: 1.3rem 1rem;
  font-size: 1rem;
  border: 0.2px solid rgb(234, 234, 234);
  margin-bottom: 10px;
}
.form input:focus {
  border: none;
}
.password input {
  width: 90%;
}
.password input span {
  font-weight: bold;
  font-size: 1.1rem;
  margin-left: 30px;
}
/* button  */
.form button {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 30px;
  padding: 0.2em 0.2em;
  font-size: 1.2rem;
  font-weight: 700;
  border-radius: 1rem;
  border: none;
  cursor: pointer;
}
.form button p {
  margin-right: 30px;
}
.form .Apple {
  color: white;
  background-color: #000000;
  font-family: "Comfortaa";
}
.form .CreateAccount {
  color: #fff;
  background: #008AB7;
  margin-top: 20px;
}
.form .CreateAccount p {
  margin-right: 0px;
  font-family: "Comfortaa";
}
.form .Google {
  /* background: #f7d7b7; */
  font-family: "Comfortaa";
}

.form p a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
  font-family: "Comfortaa";
  /* border-bottom: solid black 1px; */
}
.loginSpan {
  color: #000;
  font-weight: bold;
  margin-left: 5px;
  text-decoration: none;
  font-family: "Comfortaa";
  
  /* border-bottom: solid black 1px; */
  cursor: pointer;
}
.underlineAuth {
  position: relative;
}

.underlineAuth:after {
  content: '';
  position: absolute;
  bottom: -2px; /*position the underline using left and bottom property*/
  left: 0;
  width: 95px; /* adjust the width of underline as you like*/
  border-bottom: 1px solid #000; /* adjust the underline width and color as you like*/
}
.underlineeAuth {
  position: relative;
}
.underlineeAuth:after {
  content: '';
  position: absolute;
  bottom: -2px; /*position the underline using left and bottom property*/
  left: 0;
  width: 103px; /* adjust the width of underline as you like*/
  border-bottom: 1px solid #000; /* adjust the underline width and color as you like*/
}
.loginUnderline {
  position: relative;
}
.loginUnderline:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 10px;
  width: 42px;
  border-bottom: 1px solid #000;
}
@media only screen and (min-width: 768px) {
  .form .password input {
    width: 95%;
  }
}

@media only screen and (min-width: 1200px) {
  .Logo {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: -200px;
    border-radis: 100%;
    padding: 5px;
  }
  .form {
    width: 45%;
    margin-top: 12%;
  }
  .form h2 {
    width: 100%;
    font-size: 2rem;
    font-weight: 600;
    font-family: "Comfortaa";
  }
  .form .password input {
    width: 95%;
  }
}
