.footerContainer {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
}
.footerDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  color: white;
  border-top: 1px solid white;
  padding-top: 10px;
  padding-bottom: 20px;
}
.footerLinks a {
  margin-right: 20px;
  text-decoration: none;
  color: white;
}
@media (max-width: 1200px) {
  .footerDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footerDetails p {
    font-size: 1rem;
  }
  .footerLinks a {
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  .footerLinks a,
  .footerDetails p {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .footerLinks a,
  .footerDetails p {
    font-size: 1.3rem;
  }
}
