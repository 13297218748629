body {
  background: #02b3c0;
}
.Logo {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 27px;
  margin-bottom: -100px;
  border-radius: 100%;
  padding: 5px;
}
.form {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: auto;
  margin-top: 30%;
  margin-bottom: 30%;
  height: auto;
  padding: 2em 1em 1em 1em;
  background: white;
  border: solid 1px rgb(234, 234, 234);
  border-radius: 10px;
  font-family: "Comfortaa";
}
.form h2 {
  align: center;
  margin-bottom: 55px;
  font-weight: 400;
  font-family: "Comfortaa";
  margin-top: -10px;
}
.plan {
  display: flex;
  width: 90%;
  height: 172px;
  position: relative;
  border: 2px solid #008ab7;
  border-radius: 10px;
  padding: 0px 4px;
  margin: auto;
  margin-bottom: 40px;
  background: #f2fcfc;
  cursor: pointer;
}
.plan .saveMoney {
  position: absolute;
  height: 45px;
  width: 45px;
  text-align: center;
  padding: 10px;
  padding-top: 14px;
  background: #02b3c0;
  color: #fff;
  left: -5%;
  top: -12%;
  border: 2px solid #02b3c0;
  border-radius: 100%;
}
.plan .radio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 20%;
}
.plan .radio input {
  height: 20px;
  border: 2px solid rgb(219, 166, 71);
}
.plan .planDetails {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 2px 1rem;
  padding-top: 0;
  padding-bottom: 0px;
}
.plan .planDetails .firstp {
  margin-bottom: 0;
  color: rgb(68, 47, 58);
}
.plan .planDetails .secondp {
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 5px;
}
.plan .planDetails :nth-child(3n) {
  text-align: left;
  color: rgb(119, 119, 119);
  max-width: 100%;
  font-size: 11px;
  margin-top: 5px;
  margin-bottom: 0;
}
.plan .planDetails .input {
  background: rgb(52, 115, 113);
  color: white;
  width: 132px;
  height: 28px;
  padding: 5px;
  margin-top: 15px;
  border-radius: 3px;
  text-align: center;
}
.benefitsContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
}
.benefitsContainer .benefits {
  display: flex;
  align-items: center;
  padding-top: 0;
  height: 50px;
}
.benefitsContainer .benefits p {
  text-align: left;
  color: rgb(119, 119, 119);
}
.benefitsContainer .email {
  justify-content: center;
}
.benefitsContainer .email p {
  text-align: left;
  margin-left: 30px;
  width: 70%;
  color: rgb(68, 47, 58);
}
.benefitsContainer button {
  width: 100%;
  margin: auto;
  margin-top: 30px;
  padding: 1em 0.2em;
  font-size: 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #fff;
  background: rgb(219, 166, 71);
}
.benefitsContainer p a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}
.underline {
  position: relative;
}

.underline:after {
  content: "";
  position: absolute;
  bottom: -2px; /*position the underline using left and bottom property*/
  left: 0;
  width: 90px; /* adjust the width of underline as you like*/
  border-bottom: 1px solid #000; /* adjust the underline width and color as you like*/
}
.underlinee {
  position: relative;
}
.underlinee:after {
  content: "";
  position: absolute;
  bottom: -2px; /*position the underline using left and bottom property*/
  left: 0;
  width: 95px; /* adjust the width of underline as you like*/
  border-bottom: 1px solid #000; /* adjust the underline width and color as you like*/
}

/*  when boolean change */
.containerColor {
  background: #f2fcfc;
  border: none;
}
.planSecondp {
  color: rgb(68, 47, 58);
}
.plan .planDetails .planInput {
  background: rgb(134, 134, 134);
}
.plan .roundDiv {
  background: rgb(218, 218, 218);
  color: rgb(134, 134, 134);
  font-weight: 500;
  border: none;
}
.hi {
  display: none;
}
.cardElement {
  background-color: #f0f0f0;
  padding: 0.8rem;
  padding-bottom: 0.5rem;
  border-radius: 10px;
}
.href {
  font-weight: bold;
}

@media only screen and (min-width: 900px) {
  html {
    font-size: 20px;
  }
  .Logo {
    margin-bottom: -260px;
  }
  .plan .planDetails :nth-child(3n) {
    font-size: 14px;
  }
  .plan .planDetails .input {
    padding: 5px;
  }
  .benefitsContainer .email p {
    text-align: center;
    margin-left: 10px;
  }
}
/*  Media query for large screen*/
@media only screen and (min-width: 1200px) {
  html {
    font-size: 16px;
  }
  .Logo {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: -150px;
    border-radis: 100%;
    padding: 5px;
  }
  .form {
    width: 45%;
    margin-top: 12%;
  }
  .form h2 {
    width: 100%;
    font-size: 2rem;
    font-weight: 600;
  }
  .plan .radio {
    flex-basis: 10%;
  }
  .plan .radio input {
    height: 30px;
    border: 2px solid rgb(219, 166, 71);
  }
  .plan .planDetails {
    padding-right: 0;
    flex-basis: 70%;
  }
  .plan .planDetails .firstp {
    margin-top: 15px;
    font-size: 1.6rem;
  }
  .plan .planDetails .secondp {
    font-size: 1.6rem;
    margin-bottom: 12px;
    margin-top: 20px;
  }
  .plan .planDetails :nth-child(3n) {
    max-width: 350px;
    font-size: 1.1rem;
  }
  .benefitsContainer .email p {
    text-align: center;
    margin-left: 0px;
    width: 50%;
    color: rgb(68, 47, 58);
    font-size: 1.5rem;
  }
  .inputDiv input {
    display: none;
  }

  .hi input {
    display: block;
    background: #4e8074;
    color: white;
    width: 132px;
    height: 35px;
    padding: 3px 1px;
    margin-top: 15px;
    font-family: "Comfortaa";
    /* margin-right: 50px; */
    text-align: center;
  }
  .planInput {
    background: rgb(134, 134, 134);
  }
  .hi {
    display: flex;
    align-items: center;
    flex-basis: 30%;
  }
  .plan .planInput {
    background: rgb(134, 134, 134);
  }
  .plan .saveMoney {
    width: "75px";
    height: "75px";
    font-weight: bold;
  }
  .cardElement {
    background-color: #f0f0f0;
    padding: 1rem;
  }
  .benefitsContainer h3 {
    font-weight: normal;
    font-size: 2rem;
  }
  .benefitsContainer .benefits p {
    font-size: 1.2rem;
  }
  .benefitsContainer .email p {
    margin-left: 10px;
  }
  .benefitsContainer button {
    width: 90%;
    font-size: 1.5rem;
    padding: 1rem 1rem;
    font-weight: bold;
  }
}

@media only screen and (min-width: 1700px) {
  .Logo {
    margin-bottom: -220px;
  }
}
