.navbarStepFour {
  background: white;
  padding: 0.8rem;
  display: flex;
  justify-content: start;
}
.navbarStepFour img {
  width: 175px;
  height: 50px;
}
.firstSectionContainerStepFour {
  /* margin-bottom: 150px; */
}
.categoriesComponentContainerStepFour {
  background: #008fb7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.categoriesSectionStepFour {
  min-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin: auto;
  margin-top: 30px;
  padding: 1rem;
  margin-bottom: 30px;
}
.titlesStepFour {
  text-align: center;
}
.titlesStepFour p {
  line-height: 1.3rem;
}

.categoriesSectionStepFour button {
  width: 70%;
  padding: 0.5rem;
  border-radius: 15px;
  border: 3px solid #fff;
  background: #01b3bf;
  color: white;
  margin-top: 30px;
  font-size: 1rem;
}

.affirmations {
  background: white;
  max-width: 900px;
  color: black;
  border-radius: 30px;
  padding: 15px;
  position: relative;
  margin-top: 30px;
}
.affirmations p {
  padding: 2px;
}
.copyImg {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 1%;
  right: 2%;
}
@media (min-width: 768px) {
  .navbarStepFour {
    background: white;
    padding: 1.2rem;
  }
  .categoriesSectionStepFour {
    padding: 1.2rem;
  }
  .firstSectionContainerStepFour {
    /* margin-bottom: 330px; */
  }
  .copyImg {
    width: 40px;
    height: 40px;
  }
  .navbarStepFour img {
    width: 190px;
  }
}
@media (min-width: 900px) {
  .navbarStepFour {
    background: white;
    padding: 1.5rem;
  }
  .categoriesSectionStepFour {
    padding: 1.5rem;
  }
  .navbarStepFour img {
    width: 190px;
    height: 70px;
  }
}
@media (min-width: 1200px) {
  .navbarStepFour {
    background: white;
    padding: 1.4rem;
  }
  .categoriesSectionStepFour {
    padding: 1.5rem;
  }

  .navbarStepFour {
    padding: 1.5rem;
  }
  .navbarStepFour img {
    width: 300px;
    height: 80px;
  }
  .firstSectionContainerStepFour {
    /* margin-bottom: 230px; */
  }
  .categoriesSectionStepFour {
    /* max-width: 600px; */
    padding-top: 20px;
  }
  .categoryLogosStepFour {
    width: 80px;
    height: 80px;
    margin: 0 5px;
  }
  .titlesStepFour {
    margin-bottom: 70px;
  }
  .titlesStepFour h1 {
    font-size: 2.3rem;
  }
  .titlesStepFour p {
    font-size: 1.4rem;
  }
  .categoriesStepFour {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1200px;
  }
  .categoryStepFour {
    width: 500px;
    font-size: 0.8rem;
    padding: 0.2rem;
    border-radius: 20px;
  }
  .firstCategoriesStepFour,
  .secondCategoriesStepFour {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
  }
  .categoriesSectionStepFour button {
    width: 14%;
    padding: 0.5rem;
    border-radius: 20px;
    border: 3px solid #fff;
    background: #01b3bf;
    color: white;
    margin-top: 50px;
    font-size: 0.8rem;
  }
  .btnDivStepFour {
    display: flex;
    flex-direction: row;
    gap: 18px;
    justify-content: center;
    width: 100%;
  }
  .affirmations {
    background: white;
    max-width: 900px;
    color: black;
    border-radius: 30px;
    padding: 25px;
    position: relative;
  }
  .affirmations p {
    padding: 1px;
    font-size: 1.3rem;
    margin-bottom: 25px;
  }
  .copyImg {
    width: 38px;
    height: 38px;
    position: absolute;
    bottom: 2%;
    right: 2%;
  }
}
