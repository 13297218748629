.navbarStepTwo {
  background: white;
  padding: 0.8rem;
  display: flex;
  justify-content: start;
}
.navbarStepTwo img {
  width: 175px;
  height: 50px;
}
.firstSectionContainerStepTwo {
  /* margin-bottom: 70px; */
}
.categoriesComponentContainerStepTwo {
  background: #008fb7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.categoriesSectionStepTwo {
  min-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin: auto;
  margin-top: 30px;
  padding: 1rem;
  margin-bottom: 30px;
}
.titlesStepTwo {
  text-align: center;
}
.titlesStepTwo h1 {
  font-size: 1.6rem;
}
.titlesStepTwo p {
  line-height: 1.3rem;
  margin-top: 50px;
}
.categoriesStepTwo {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 90%;
  margin: auto;
  margin-top: 20px;
}
.firstCategoriesStepTwo,
.secondCategoriesStepTwo {
  /* width: 100%; */
}
.categoryStepTwo {
  background: white;
  color: #000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  /* padding: 0 0.5rem; */
  padding-left: 0.3rem;
  border-radius: 15px;
}
.categoryLogosStepTwo {
  width: 50px;
  height: 50px;
  margin: 0 5px;
}
.imgAndTextDivStepTwo {
  display: flex;
}
.imgAndTextDivStepTwo {
  font-weight: 600;
}

.categoriesSectionStepTwo button {
  width: 70%;
  padding: 0.5rem;
  border-radius: 15px;
  border: 3px solid #fff;
  background: #01b3bf;
  color: white;
  margin-top: 30px;
  font-size: 1rem;
}
.btnDivStepTwo {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .navbarStepTwo {
    background: white;
    padding: 1.2rem;
  }
  .categoriesSectionStepTwo {
    padding: 1.3rem;
  }
  .titlesStepTwo p {
    line-height: 1.7rem;
  }
  .navbarStepTwo img {
    width: 170px;
  }
  .categoryStepTwo {
    margin-bottom: 1.3rem;
    /* padding: 0 0.5rem; */
    padding-left: 0.2rem;
  }
  .imgAndTextDivStepTwo {
    padding: 0.1rem;
  }
  .categoriesSectionStepTwo button {
    padding: 0.3rem;
  }
}
@media (min-width: 900px) {
  .navbarStepTwo {
    background: white;
    padding: 1.5rem;
  }
  .categoriesSectionStepTwo {
    padding: 1.6rem;
  }
  .titlesStepTwo p {
    line-height: 2.1rem;
  }
  .navbarStepTwo img {
    width: 170px;
  }
  .categoryStepTwo {
    margin-bottom: 1.7rem;
    /* padding: 0 0.5rem; */
    padding-left: 0.1rem;
  }
  .imgAndTextDivStepTwo {
    padding: 0.1rem;
  }
  .categoriesSectionStepTwo button {
    padding: 0.1rem;
  }
  .navbarStepTwo img {
    width: 170px;
  }
}
@media (min-width: 1200px) {
  .navbarStepTwo {
    background: white;
    padding: 1.9rem;
  }

  .categoriesSectionStepTwo {
    padding: 1.6rem;
  }

  .titlesStepTwo p {
    line-height: 2.1rem;
  }

  .categoryStepTwo {
    margin-bottom: 1.7rem;
    /* padding: 0 0.5rem; */
    padding-left: 0.4rem;
  }
  .imgAndTextDivStepTwo {
    padding: 0.1rem;
  }
  .categoriesSectionStepTwo button {
    padding: 0.1rem;
  }

  .navbarStepTwo img {
    width: 200px;
    height: 60px;
  }
  .navbarStepTwo {
    padding: 1.5rem;
  }
  .navbarStepTwo img {
    width: 300px;
    height: 70px;
  }
  .firstSectionContainerStepTwo {
    /* margin-bottom: 260px; */
  }
  .categoriesSectionStepTwo {
    /* max-width: 600px; */
    padding-top: 100px;
  }
  .categoryLogosStepTwo {
    width: 60px;
    height: 60px;
    margin: 0 5px;
  }
  .titlesStepTwo {
    margin-bottom: 70px;
  }
  .titlesStepTwo h1 {
    font-size: 2.4rem;
  }
  .titlesStepTwo p {
    font-size: 1.4rem;
  }
  .categoriesStepTwo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1200px;
  }
  .categoryStepTwo {
    width: 400px;
    font-size: 1.2rem;
    border-radius: 20px;
  }
  .firstCategoriesStepTwo,
  .secondCategoriesStepTwo {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
  }
  .categoriesSectionStepTwo button {
    width: 14%;
    padding: 0.5rem;
    border-radius: 20px;
    border: 3px solid #fff;
    background: #01b3bf;
    color: white;
    margin-top: 50px;
    font-size: 1.3rem;
  }
  .btnDivStepTwo {
    display: flex;
    flex-direction: row;
    gap: 18px;
    justify-content: center;
    width: 100%;
  }
}
