body,
html {
  min-height: 100%;
}
.navBarStepOne {
  background: white;
  padding: 0.8rem;
  display: flex;
  justify-content: start;
}
.navBarStepOne img {
  width: 175px;
  height: 50px;
}
.categoriesComponentContainerStepOne {
  background: #008fb7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.categoriesSectionStepOne {
  min-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin: auto;
  margin-top: 20px;
  padding: 1rem;
  margin-bottom: 30px;
}
.titlesStepOne {
  text-align: center;
}
.titlesStepOne p {
  line-height: 1.3rem;
}
.categoriesStepOne {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 90%;
  margin: auto;
}
.firstCategoriesStepOne,
.secondCategoriesStepOne {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.categoryStepOne {
  background: white;
  color: #000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
  border-radius: 15px;
}
.categoryLogosStepOne {
  width: 45px;
  height: 45px;
  margin: 0 10px;
}
.imgAndTextDivStepOne {
  display: flex;
  align-items: center;
}

.categoriesSectionStepOne button {
  width: 70%;
  padding: 0.5rem;
  border-radius: 15px;
  border: 3px solid #fff;
  background: #01b3bf;
  color: white;
  margin-top: 30px;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .categoriesSectionStepOne {
    padding: 1.3rem;
  }
  .titlesStepOne h1 {
    font-size: 2rem;
  }
  .titlesStepOne p {
    line-height: 1.7rem;
    font-size: 1.2rem;
  }
  .categoryStepOne {
    font-size: 1.2rem;
  }
  .navbarStepOne img {
    width: 170px;
  }
  .categoryStepOne {
    margin-bottom: 1.31rem;
    padding: 0 0.65rem;
  }
  .categoriesSectionStepOne button {
    padding: 0.65rem;
    font-size: 1.31rem;
  }
}
@media (min-width: 900px) {
  .navBarStepOne {
    padding: 1.5rem;
  }
  .navBarStepOne img {
    width: 200px;
    height: 60px;
  }
  .categoriesSectionStepOne {
    padding: 1.5rem;
  }
  .titlesStepOne p {
    line-height: 1.9rem;
  }
  .navbarStepOne img {
    width: 170px;
  }
  .categoryStepOne {
    margin-bottom: 1.45rem;
    padding: 0 0.35rem;
  }
  .navbarStepOne img {
    width: 170px;
  }
}
@media (min-width: 1200px) {
  .navBarStepOne {
    padding: 1.5rem;
  }
  .navBarStepOne img {
    width: 300px;
    height: 70px;
  }
  .firstSectionContainerStepOne {
    /* margin-bottom: 210px; */
  }
  .categoriesSectionStepOne {
    /* max-width: 600px; */
    padding-top: 50px;
  }
  .titlesStepOne {
    margin-bottom: 70px;
  }
  .titlesStepOne p {
    font-size: 1.4rem;
  }
  .titlesStepOne h1 {
    font-size: 2.4rem;
  }
  .categoriesStepOne {
    flex-direction: row;
    max-width: 1200px;
  }
  .categoryStepOne {
    width: 500px;
    font-size: 1.3rem;
    padding: 0.2rem;
    border-radius: 20px;
  }
  .categoryLogosStepOne {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }
  .categoriesSectionStepOne button {
    width: 18%;
    padding: 0.5rem;
    border-radius: 20px;
    border: 3px solid #fff;
    background: #01b3bf;
    color: white;
    margin-top: 30px;
    font-size: 1.3rem;
  }
}
