body {
  font-family: "Comfortaa";
}
/* navbar */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  padding-bottom: 20px;
  background: white;
}
.logo {
  width: 200px;
  padding: 10px;
  padding-left: 30px;
}
.logo img {
  width: 100%;
  height: 45px;
}
.link {
  margin-right: 20px;
  font-size: 1.5rem;
  text-decoration: none;
  color: black;
  font-weight: bold;
  padding: 10px;
}
/* second section */
.secondSection {
  display: flex;
  gap: 50px;
  padding: 30px;
  padding-top: 15px;
  max-width: 100%;
  margin-top: 30px;
}
/* second section sidebar */
.sideBar {
  background: white;
  width: 20%;
  padding: 20px;
  border-radius: 8%;
}
.sideBarTextDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* min-width: 150px; */
}
.accountLogoDiv {
  width: 30%;
  text-align: right;
}
.sideBarTextDiv img {
  width: 20px;
  height: 20px;
}
.sideBarTextDiv h2 {
  text-align: left;
  color: #58c0ed;
  font-size: 1.3rem;
  font-weight: 800;
  margin-left: 10px;
  width: 100%;
  min-width: 150px;
}

/* second section account Details */

.accountDetails {
  padding-top: 5px;
  background: white;
  border-radius: 20px;
  padding: 1px;
}

.accountDetailsTextDiv {
  padding: 10px;
  background: #ecedef;
  color: #727272;
  padding-left: 20px;
  max-width: 100%;
  border-bottom: solid 1px #bdbfbf;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.accountDetailsTextDiv h1 {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 700;
}
.susbcriptionSection {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  margin-top: 50px;
}
table {
  padding: 30px;
  padding-top: 0px;
  flex-basis: 70%;
}
/* table, th, td {
    border:1px solid black;
  } */
th,
td {
  width: 600px;
  padding: 10px;
}
th {
  font-size: 1.3rem;

  text-align: left;
  font-weight: bold;
}
td {
  font-size: 1.1rem;
}
.subscriptionBtnDiv {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 40px;
  flex-basis: 30%;
}
.subscriptionBtn {
  height: 60px;
  border: none;
  border-radius: 20px;
  background: #0091b8;
  text-align: center;
  font-size: 1.3rem;
  color: white;
  padding: 35px 40px;
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: pointer;
}
.mobileSectionContainer {
  display: none;
}
.modal {
  position: absolute;
  top: 35%;
  right: 15%;
  left: 33%;
  bottom: 40%;
  box-shadow: 3px 3px 3px 3px #757575;
  text-align: center;
  background: white;
  max-width: 900px;
  border-radius: 20px;
}
.modal p {
  font-size: 1.7rem;
  line-height: 3.2rem;
  max-width: 500px;
  margin: auto;
  margin-top: 100px;
  font-weight: 500;
}
.innerModalDiv {
  background: gray;

  position: absolute;
  border-radius: 5px;
  width: 37px;
  height: 37px;
  top: 3%;
  right: 3%;
  border: #2d2e2e;
}
.innerModalDiv img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .susbcriptionSection {
    display: none;
  }
  nav {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding: 6px;
  }
  .logo {
    display: flex;
    align-items: center;
    width: 120px;
    height: 30px;
    padding-left: 10px;
  }
  .link {
    font-size: 1rem;
    margin-right: 3px;
    padding-right: 12px;
  }
  .secondSection {
    display: none;
  }
  .mobileSectionContainer {
    display: flex;
    flex-direction: column;
    padding: 15px;
    padding-top: 30px;
    gap: 3rem;
    min-width: 90%;
  }
  .sideBarMobile {
    display: flex;
    flex-direction: column;
    align-items: start;
    background: lightblue;
    border-radius: 8px;
    padding: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sideBarMobileText {
    display: flex;
    align-items: center;
    padding: 0px;
  }
  .sideBarMobileText h3:hover {
    font-weight: 700;
    cursor: pointer;
    color: white;
  }
  .sideBarMobileText h3:focus {
    color: white;
    font-weight: 700;
  }

  .sideBarMobileText h3 {
    color: black;
    font-weight: normal;
  }
  /* .sideBarMobileText img {
    width: 20px;
    height: 20px;
  } */
  .accountDetailsMobile {
    background: lightblue;
    border-radius: 8px;
    margin-bottom: 30px;
  }
  .accountDetailsTextDivMobile {
    display: flex;
    padding-left: 20px;
    max-width: 100%;
    background: #ecedef;
    color: #727272;
    border-bottom: solid 1px #bdbfbf;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .susbcriptionSectionMobile {
    padding-left: 30px;
  }
  .susbcriptionSectionMobile h3 {
    font-weight: normal;
    font-size: 1rem;
  }
  .susbcriptionSectionMobile .th {
    font-size: 1.1rem;

    font-weight: bold;
  }
  .cancelSubBtn {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    margin-top: 40px;
  }
  .cancelSubBtn button {
    font-size: 1.2rem;
    padding: 13px 20px;
    background: #0091b8;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 8px;
  }
  /*  Modal */
  .modal {
    position: absolute;
    top: 35%;
    right: 15%;
    left: 15%;
    bottom: 40%;
    box-shadow: 3px 3px 3px 3px #757575;
    text-align: center;
    background: white;
    min-width: 300px;
    border-radius: 20px;
  }
  .modal p {
    font-size: 1.1rem;
    line-height: 2.2rem;
    min-width: 200px;
    margin: auto;
    margin-top: 75px;
    font-weight: 500;
  }
  .innerModalDiv {
    background: gray;

    position: absolute;
    border-radius: 5px;
    width: 27px;
    height: 27px;
    top: 3%;
    right: 3%;
    border: #2d2e2e;
  }
  .innerModalDiv img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .logo {
    width: 140px;
  }
  .modal p {
    font-size: 1.4rem;
    margin-top: 120px;
  }
}
