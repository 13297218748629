/* html,
body {
  height: 100%;
} */
.navBarStepThree {
  background: white;
  padding: 1.5rem;
  display: flex;
  justify-content: start;
}
.navBarStepThree img {
  width: 175px;
  height: 50px;
}
.categoriesComponentContainerStepThree {
  background: #008fb7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.firstSectionContainer {
  /* margin-bottom: 80px; */
}
.categoriesSectionStepThree {
  min-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin: auto;
  margin-top: 40px;
  padding: 1rem;
  margin-bottom: 95px;
}
select {
  width: 60px;
  height: 50px;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 1.4rem;
  margin-top: 1.4rem;
  border-radius: 22px;
}
.titlesStepThree {
  text-align: center;
}

.titlesStepThree p {
  line-height: 1.6rem;
  margin-top: 40px;
  font-size: 1.1rem;
}
.categoriesSectionStepThree button {
  width: 60%;
  padding: 0.5rem 0.7rem;
  border-radius: 20px;
  border: 3px solid #fff;
  background: #01b3bf;
  color: white;
  margin-top: 95px;
  font-size: 1rem;
}
.categoriesSectionStepThree input {
  padding: 1rem;
  border-radius: 20px;
  border: none;
  text-align: center;
  font-size: 1rem;
  margin-top: 50px;
}
@media (min-width: 768px) {
  .navBarStepThree img {
    width: 170px;
  }

  .categoriesSectionStepThree {
    margin-bottom: 180px;
    padding: 1.3rem;
  }
  .categoriesSectionStepThree button {
    margin-top: 120px;
  }
  select {
    width: 60px;
    height: 50px;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1.7rem;
    margin-top: 1.7rem;
    border-radius: 22px;
  }
  .titlesStepThree p {
    line-height: 1.9rem;
    margin-top: 40px;
    font-size: 1.4rem;
  }
}
@media (min-width: 900px) {
  .navBarStepThree img {
    width: 170px;
  }

  .categoriesSectionStepThree {
    margin-bottom: 180px;
    padding: 1.8rem;
  }
  .categoriesSectionStepThree button {
    margin-top: 120px;
  }
  select {
    width: 60px;
    height: 50px;
    text-align: center;
    font-size: 1.9rem;
    margin-bottom: 2.2rem;
    margin-top: 2.2rem;
    border-radius: 22px;
  }
  .titlesStepThree p {
    line-height: 2.4rem;
    margin-top: 40px;
    font-size: 2.4rem;
  }
  .navBarStepThree img {
    width: 170px;
  }
  select {
    width: 80px;
    height: 80px;
  }
  .categoriesSectionStepThree button {
    width: 70%;
    padding: 0.5rem;
    border-radius: 15px;
    border: 3px solid #fff;
    background: #01b3bf;
    color: white;
    margin-top: 30px;
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .navBarStepThree img {
    width: 170px;
  }

  .categoriesSectionStepThree {
    /* margin-bottom: 180px; */
    /* padding: 1.9rem; */
  }
  .categoriesSectionStepThree button {
    margin-top: 120px;
  }
  .navbarStepTwo {
    padding: 1.5rem;
  }
  .navbarStepTwo img {
    width: 300px;
    height: 70px;
  }
  select {
    width: 60px;
    height: 50px;
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 2.3rem;
    margin-top: 2.4rem;
    border-radius: 22px;
  }
  .titlesStepThree p {
    line-height: 1.8rem;
    margin-top: 40px;
    font-size: 1.3rem;
  }
  .navBarStepThree {
  }
  .navBarStepThree img {
    width: 300px;
    height: 70px;
  }
  select {
    width: 80px;
    height: 80px;
  }
  .categoriesSectionStepThree {
    /* max-width: 600px; */
    padding-top: 80px;
  }
  .titlesStepThree {
    margin-bottom: 70px;
  }
  .titlesStepThree h1 {
    font-size: 2.5rem;
  }
  .titlesStepThree p {
    font-size: 1.5rem;
  }

  select {
    width: 80px;
    font-size: 1.9rem;
  }
  .categoriesSectionStepThree input {
    font-size: 1.5rem;
    padding: 1.2rem;
  }
  .categoriesSectionStepThree button {
    width: 20%;
    padding: 0.8rem 1em;
    border-radius: 29px;
    border: 3px solid #fff;
    background: #01b3bf;
    color: white;
    margin-top: 60px;
    font-size: 1.2rem;
  }
}
/* @media (max-width: 280px) {
  html {
    font-size: 14px;
  }
  .navBarStepThree img {
    width: 100px;
    height: 35px;
  }
} */
